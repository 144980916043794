exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-list-js": () => import("./../../../src/pages/price-list.js" /* webpackChunkName: "component---src-pages-price-list-js" */),
  "component---src-pages-single-blog-blog-post-slug-js": () => import("./../../../src/pages/single-blog/{blogPost.slug}.js" /* webpackChunkName: "component---src-pages-single-blog-blog-post-slug-js" */),
  "component---src-pages-team-json-url-name-js": () => import("./../../../src/pages/{teamJson.urlName}.js" /* webpackChunkName: "component---src-pages-team-json-url-name-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

